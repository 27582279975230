// src/pages/CatalogItems/CatalogItemsList.js
import React, { useContext, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import { Container, Typography, Button, TextField, Grid } from '@mui/material';
import AddCatalogItemModal from '../../components/AddCatalogItemModal';
import EditCatalogItemModal from '../../components/EditCatalogItemModal';
import CatalogItemCard from '../../components/CatalogItemCard';

const CatalogItemsList = () => {
  const { catalogItems, removeCatalogItem } = useContext(CRMContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const filteredItems = catalogItems.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function handleEdit(item) {
    setSelectedItem(item);
    setShowEditModal(true);
  }

  async function handleDelete(itemId) {
    await removeCatalogItem(itemId);
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Catalog Items</Typography>
      <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12} sm={8}>
          <TextField 
            fullWidth 
            label="Search Items" 
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)} 
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={() => setShowAddModal(true)}>
            Add Item
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {filteredItems.map(item => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <CatalogItemCard 
              item={item} 
              onEdit={handleEdit} 
              onDelete={handleDelete} 
            />
          </Grid>
        ))}
      </Grid>

      <AddCatalogItemModal open={showAddModal} onClose={() => setShowAddModal(false)} />
      {selectedItem && (
        <EditCatalogItemModal 
          open={showEditModal} 
          onClose={() => setShowEditModal(false)} 
          item={selectedItem} 
        />
      )}
    </Container>
  );
};

export default CatalogItemsList;
