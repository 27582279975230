// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AuthProvider } from './context/AuthContext';
import { CRMProvider } from './context/CRMContext';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ProtectedRoute from './components/ProtectedRoute';

import Dashboard from './pages/Dashboard';
import ContactsList from './pages/Contacts/ContactsList';
import DealsList from './pages/Deals/DealsList';
import CatalogItemsList from './pages/CatalogItems/CatalogItemsList';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import MarketingPage from './pages/Marketing/MarketingPage';  // The new marketing page

import { Box } from '@mui/material';
import { AuthContext } from './context/AuthContext';

const AuthLayout = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Box display="flex">
      {currentUser && <Sidebar />} {/* Only show sidebar if logged in */}
      <Box flex={1} p={2}>
        {children}
      </Box>
    </Box>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CRMProvider>
          <Router>
            <Navbar />
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              
              {/* Protected Routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <Dashboard />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contacts"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <ContactsList />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/deals"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <DealsList />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/products"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <CatalogItemsList />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketing"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <MarketingPage />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <Reports />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <AuthLayout>
                      <Settings />
                    </AuthLayout>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </CRMProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
