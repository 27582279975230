// src/pages/Settings.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Settings = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>Settings</Typography>
        <Typography variant="body1">
          User and company settings.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Settings;
