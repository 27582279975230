// src/components/CatalogItemCard.js
import React from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';

const CatalogItemCard = ({ item, onEdit, onDelete }) => {
  if (!item) return null; // safety check

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{item.name}</Typography>
        <Typography variant="body2">Price: ${item.price}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" onClick={() => onEdit(item)}>Edit</Button>
        <Button size="small" color="error" variant="contained" onClick={() => onDelete(item.id)}>Delete</Button>
      </CardActions>
    </Card>
  );
};

export default CatalogItemCard;
