// src/components/AddContactModal.js
import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button, Typography, Paper } from '@mui/material';
import { CRMContext } from '../context/CRMContext';

const AddContactModal = ({ open, onClose }) => {
  const { addNewContact } = useContext(CRMContext);
  const [formValues, setFormValues] = useState({ name: '', email: '', phone: '' });

  async function handleSubmit() {
    await addNewContact(formValues);
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        component={Paper}
        sx={{
          position: 'absolute', top: '50%', left: '50%', 
          transform: 'translate(-50%, -50%)', p: 4, width: 400
        }}
      >
        <Typography variant="h5" gutterBottom>Add Contact</Typography>
        <TextField 
          fullWidth 
          label="Name" 
          margin="normal" 
          value={formValues.name} 
          onChange={e => setFormValues({...formValues, name: e.target.value})}
        />
        <TextField 
          fullWidth 
          label="Email" 
          margin="normal" 
          value={formValues.email} 
          onChange={e => setFormValues({...formValues, email: e.target.value})}
        />
        <TextField 
          fullWidth 
          label="Phone" 
          margin="normal" 
          value={formValues.phone} 
          onChange={e => setFormValues({...formValues, phone: e.target.value})}
        />

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddContactModal;
