// src/pages/Reports.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Reports = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>Reports</Typography>
        <Typography variant="body1">
          Analytics and metrics will go here.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Reports;
