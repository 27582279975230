import React, { createContext, useState, useContext } from 'react';
import { auth } from '../firebase';
import { AuthContext } from './AuthContext';

import {
  getAllContacts, addContact, updateContact, deleteContact
} from '../services/contacts';

import {
  getAllDeals, addDeal, updateDeal, deleteDeal
} from '../services/deals';

import {
  getAllCatalogItems, addCatalogItem, updateCatalogItem, deleteCatalogItem
} from '../services/catalogItems';

import {
  getAllMarketingCampaigns, addMarketingCampaign, updateMarketingCampaign, deleteMarketingCampaign
} from '../services/marketingCampaigns';

export const CRMContext = createContext();

export const CRMProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);

  // State
  const [contacts, setContacts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [catalogItems, setCatalogItems] = useState([]);
  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [initialized, setInitialized] = useState(false);

  // Fetch Data Once User is Authenticated
  React.useEffect(() => {
    if (currentUser && !initialized) {
      fetchInitialData();
    }
  }, [currentUser, initialized]);

  async function fetchInitialData() {
    if (!currentUser) return;
    const [contactsData, dealsData, catalogData, campaignsData] = await Promise.all([
      getAllContacts(),
      getAllDeals(),
      getAllCatalogItems(),
      getAllMarketingCampaigns()
    ]);
    setContacts(contactsData);
    setDeals(dealsData);
    setCatalogItems(catalogData);
    setMarketingCampaigns(campaignsData);
    setInitialized(true);
  }

  // CONTACTS
  async function addNewContact(contact) {
    const newContact = { ...contact, userId: auth.currentUser.uid };
    await addContact(newContact);
    await refreshContacts();
  }
  async function editContact(contactId, updateData) {
    await updateContact(contactId, updateData);
    await refreshContacts();
  }
  async function removeContact(contactId) {
    await deleteContact(contactId);
    await refreshContacts();
  }
  async function refreshContacts() {
    if (!currentUser) return;
    const data = await getAllContacts();
    setContacts(data);
  }

  // DEALS
  async function addNewDeal(deal) {
    const newDeal = { ...deal, userId: auth.currentUser.uid };
    await addDeal(newDeal);
    await refreshDeals();
  }
  async function editDeal(dealId, updateData) {
    await updateDeal(dealId, updateData);
    await refreshDeals();
  }
  async function removeDeal(dealId) {
    await deleteDeal(dealId);
    await refreshDeals();
  }
  async function refreshDeals() {
    if (!currentUser) return;
    const data = await getAllDeals();
    setDeals(data);
  }

  // CATALOG ITEMS
  async function addNewCatalogItem(item) {
    const newItem = { ...item, userId: auth.currentUser.uid };
    await addCatalogItem(newItem);
    await refreshCatalogItems();
  }
  async function editCatalogItem(itemId, updateData) {
    await updateCatalogItem(itemId, updateData);
    await refreshCatalogItems();
  }
  async function removeCatalogItem(itemId) {
    await deleteCatalogItem(itemId);
    await refreshCatalogItems();
  }
  async function refreshCatalogItems() {
    if (!currentUser) return;
    const data = await getAllCatalogItems();
    setCatalogItems(data);
  }

  // MARKETING CAMPAIGNS
  async function addNewMarketingCampaign(campaign) {
    const newCampaign = { ...campaign, userId: auth.currentUser.uid };
    await addMarketingCampaign(newCampaign);
    await refreshMarketingCampaigns();
  }
  async function editMarketingCampaign(campaignId, updateData) {
    await updateMarketingCampaign(campaignId, updateData);
    await refreshMarketingCampaigns();
  }
  async function removeMarketingCampaign(campaignId) {
    await deleteMarketingCampaign(campaignId);
    await refreshMarketingCampaigns();
  }
  async function refreshMarketingCampaigns() {
    if (!currentUser) return;
    const data = await getAllMarketingCampaigns();
    setMarketingCampaigns(data);
  }

  return (
    <CRMContext.Provider value={{
      contacts, deals, catalogItems, marketingCampaigns,
      addNewContact, editContact, removeContact,
      addNewDeal, editDeal, removeDeal,
      addNewCatalogItem, editCatalogItem, removeCatalogItem,
      addNewMarketingCampaign, editMarketingCampaign, removeMarketingCampaign
    }}>
      {children}
    </CRMContext.Provider>
  );
};
