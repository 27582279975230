// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDKBYhEByWY-CK53uGb66fNcjvvOjL5Jss",
    authDomain: "finli-efed8.firebaseapp.com",
    projectId: "finli-efed8",
    storageBucket: "finli-efed8.firebasestorage.app",
    messagingSenderId: "536456391206",
    appId: "1:536456391206:web:cf871c0261ad0f0cf0d4e7",
    measurementId: "G-PY8MK714NG"
  };
  

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);