import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button, Typography, Paper, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { CRMContext } from '../context/CRMContext';

const sources = ["Google Ads", "Facebook Ads", "Reddit Ads", "LinkedIn Ads"];
const statuses = ["Active", "Paused", "Completed"];

const AddMarketingCampaignModal = ({ open, onClose }) => {
  const { addNewMarketingCampaign } = useContext(CRMContext); 
  const [formValues, setFormValues] = useState({ 
    source: '', 
    dailyBudget: '', 
    location: '', 
    dailySpent: '', 
    startDate: '', 
    endDate: '', 
    ongoing: false, 
    status: 'Active' 
  });

  async function handleSubmit() {
    if (!formValues.source || !formValues.dailyBudget.trim() || isNaN(Number(formValues.dailyBudget)) || !formValues.location.trim() || !formValues.startDate.trim()) {
      alert('Please provide a valid source, daily budget (number), location, and start date.');
      return;
    }

    await addNewMarketingCampaign({
      source: formValues.source,
      dailyBudget: Number(formValues.dailyBudget),
      location: formValues.location,
      dailySpent: (formValues.dailySpent.trim() && !isNaN(Number(formValues.dailySpent))) 
        ? Number(formValues.dailySpent) 
        : 0,
      startDate: formValues.startDate,
      endDate: formValues.ongoing ? null : formValues.endDate.trim() || null,
      ongoing: formValues.ongoing,
      status: formValues.status
    });
    onClose();
    setFormValues({ source: '', dailyBudget: '', location: '', dailySpent: '', startDate: '', endDate: '', ongoing: false, status: 'Active' });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        component={Paper}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          p: 4, 
          width: 400
        }}
      >
        <Typography variant="h5" gutterBottom>Add Marketing Campaign</Typography>
        <TextField 
          select
          fullWidth 
          label="Source" 
          margin="normal" 
          value={formValues.source} 
          onChange={e => setFormValues({...formValues, source: e.target.value})}
        >
          {sources.map(src => <MenuItem key={src} value={src}>{src}</MenuItem>)}
        </TextField>
        <TextField 
          fullWidth 
          label="Daily Budget" 
          margin="normal"
          value={formValues.dailyBudget}
          onChange={e => setFormValues({...formValues, dailyBudget: e.target.value})}
        />
        <TextField 
          fullWidth 
          label="Location" 
          margin="normal"
          value={formValues.location} 
          onChange={e => setFormValues({...formValues, location: e.target.value})}
        />
        <TextField
          fullWidth
          label="Daily Spent (optional)"
          margin="normal"
          value={formValues.dailySpent}
          onChange={e => setFormValues({ ...formValues, dailySpent: e.target.value })}
        />
        <TextField
          fullWidth
          label="Start Date (YYYY-MM-DD)"
          margin="normal"
          value={formValues.startDate}
          onChange={e => setFormValues({ ...formValues, startDate: e.target.value })}
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={formValues.ongoing}
              onChange={e => setFormValues({ ...formValues, ongoing: e.target.checked })}
            />
          } 
          label="Ongoing (no end date)"
        />
        {!formValues.ongoing && (
          <TextField
            fullWidth
            label="End Date (YYYY-MM-DD) - optional"
            margin="normal"
            value={formValues.endDate}
            onChange={e => setFormValues({ ...formValues, endDate: e.target.value })}
          />
        )}
        <TextField
          select
          fullWidth
          label="Status"
          margin="normal"
          value={formValues.status}
          onChange={e => setFormValues({ ...formValues, status: e.target.value })}
        >
          {statuses.map(st => <MenuItem key={st} value={st}>{st}</MenuItem>)}
        </TextField>

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddMarketingCampaignModal;
