// src/services/catalogItems.js
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';

const catalogRef = collection(db, 'catalogItems');

export async function getAllCatalogItems() {
  const snapshot = await getDocs(catalogRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function addCatalogItem(itemData) {
  const newItem = {
    ...itemData,
    createdAt: Timestamp.now()
  };
  const docRef = await addDoc(catalogRef, newItem);
  return docRef.id;
}

export async function updateCatalogItem(itemId, updateData) {
  const itemDoc = doc(db, 'catalogItems', itemId);
  await updateDoc(itemDoc, {
    ...updateData,
    updatedAt: Timestamp.now()
  });
}

export async function deleteCatalogItem(itemId) {
  const itemDoc = doc(db, 'catalogItems', itemId);
  await deleteDoc(itemDoc);
}
