// src/pages/Contacts/ContactsList.js
import React, { useContext, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import { Container, Typography, Button, TextField, Grid } from '@mui/material';
import ContactCard from '../../components/ContactCard';
import AddContactModal from '../../components/AddContactModal';
import EditContactModal from '../../components/EditContactModal';

const ContactsList = () => {
  const { contacts, removeContact } = useContext(CRMContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function handleEdit(contact) {
    setSelectedContact(contact);
    setShowEditModal(true);
  }

  async function handleDelete(contactId) {
    await removeContact(contactId);
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Contacts</Typography>
      <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
        <Grid item xs={12} sm={8}>
          <TextField 
            fullWidth 
            label="Search Contacts" 
            value={searchTerm} 
            onChange={e => setSearchTerm(e.target.value)} 
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={() => setShowAddModal(true)}>
            Add Contact
          </Button>
        </Grid>
      </Grid>
      
      <Grid container spacing={2}>
        {filteredContacts.map(contact => (
          <Grid item xs={12} sm={6} md={4} key={contact.id}>
            <ContactCard 
              contact={contact} 
              onEdit={handleEdit} 
              onDelete={handleDelete} 
            />
          </Grid>
        ))}
      </Grid>

      <AddContactModal open={showAddModal} onClose={() => setShowAddModal(false)} />
      {selectedContact && (
        <EditContactModal 
          open={showEditModal} 
          onClose={() => setShowEditModal(false)} 
          contact={selectedContact} 
        />
      )}
    </Container>
  );
};

export default ContactsList;
