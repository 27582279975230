import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';

const dealsRef = collection(db, 'deals');

export async function getAllDeals() {
  const snapshot = await getDocs(dealsRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function addDeal(dealData) {
  const newDeal = {
    ...dealData,
    createdAt: Timestamp.now()
  };
  const docRef = await addDoc(dealsRef, newDeal);
  return docRef.id;
}

export async function updateDeal(dealId, updateData) {
  const dealDoc = doc(db, 'deals', dealId);
  await updateDoc(dealDoc, {
    ...updateData,
    updatedAt: Timestamp.now()
  });
}

export async function deleteDeal(dealId) {
  const dealDoc = doc(db, 'deals', dealId);
  await deleteDoc(dealDoc);
}
