// src/pages/Deals/DealsList.js
import React, { useContext, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import { Container, Typography, Grid, Button } from '@mui/material';
import DealCard from '../../components/DealCard';
import AddDealModal from '../../components/AddDealModal';
import EditDealModal from '../../components/EditDealModal';

const DealsList = () => {
  const { deals, removeDeal } = useContext(CRMContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  function handleEdit(deal) {
    setSelectedDeal(deal);
    setShowEditModal(true);
  }

  async function handleDelete(dealId) {
    await removeDeal(dealId);
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Deals</Typography>
      <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
        <Button variant="contained" color="primary" onClick={() => setShowAddModal(true)}>
          Add Deal
        </Button>
      </div>
      <Grid container spacing={2}>
        {deals.map(deal => (
          <Grid item xs={12} sm={6} md={4} key={deal.id}>
            <DealCard 
              deal={deal}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
      <AddDealModal open={showAddModal} onClose={() => setShowAddModal(false)} />
      {selectedDeal && (
        <EditDealModal 
          open={showEditModal} 
          onClose={() => setShowEditModal(false)} 
          deal={selectedDeal} 
        />
      )}
    </Container>
  );
};

export default DealsList;
