// src/pages/Home.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Home = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>Welcome to Orthinas CRM</Typography>
        <Typography variant="body1">
          Please sign in or sign up to access your dashboard.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Home;
