// src/components/Sidebar.js
import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  Toolbar,
  ListItemIcon,
  Box,
  Typography,
  Divider
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ContactsIcon from '@mui/icons-material/Contacts';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CampaignIcon from '@mui/icons-material/Campaign'; // Icon for marketing

const drawerWidth = 240;

const Sidebar = () => {
  const location = useLocation();

  const navItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Contacts', icon: <ContactsIcon />, path: '/contacts' },
    { text: 'Deals', icon: <MonetizationOnIcon />, path: '/deals' },
    { text: 'Products', icon: <StorefrontIcon />, path: '/products' },
    { text: 'Marketing', icon: <CampaignIcon />, path: '/marketing' }, // Added Marketing
    { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: '1px solid #ddd',
          backgroundColor: 'background.paper'
        },
      }}
    >
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography variant="h6" noWrap>
            Orthinas CRM
          </Typography>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {navItems.map(item => {
          const selected = location.pathname.startsWith(item.path);
          return (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              sx={{
                backgroundColor: selected ? 'action.hover' : 'inherit',
                '&:hover': {
                  backgroundColor: 'action.selected',
                }
              }}
            >
              <ListItemIcon sx={{ color: selected ? 'primary.main' : 'inherit' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontWeight: selected ? 'bold' : 'normal',
                  color: selected ? 'primary.main' : 'text.primary'
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
