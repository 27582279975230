import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';

const contactsRef = collection(db, 'contacts');

export async function getAllContacts() {
  const snapshot = await getDocs(contactsRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function addContact(contactData) {
  const newContact = {
    ...contactData,
    createdAt: Timestamp.now()
  };
  const docRef = await addDoc(contactsRef, newContact);
  return docRef.id;
}

export async function updateContact(contactId, updateData) {
  const contactDoc = doc(db, 'contacts', contactId);
  await updateDoc(contactDoc, {
    ...updateData,
    updatedAt: Timestamp.now()
  });
}

export async function deleteContact(contactId) {
  const contactDoc = doc(db, 'contacts', contactId);
  await deleteDoc(contactDoc);
}
