// src/pages/Marketing/MarketingPage.js
import React, { useContext, useState } from 'react';
import { CRMContext } from '../../context/CRMContext';
import { Container, Typography, Button, Grid, Card, CardContent, CardActions } from '@mui/material';
import AddMarketingCampaignModal from '../../components/AddMarketingCampaignModal';
import EditMarketingCampaignModal from '../../components/EditMarketingCampaignModal';

const MarketingPage = () => {
  const { marketingCampaigns = [], removeMarketingCampaign } = useContext(CRMContext); 
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const totalDailySpending = marketingCampaigns.reduce((sum, c) => sum + (c.dailySpent || 0), 0);

  function handleEdit(campaign) {
    setSelectedCampaign(campaign);
    setShowEditModal(true);
  }

  async function handleDelete(campaignId) {
    await removeMarketingCampaign(campaignId);
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>Marketing Campaigns</Typography>
      <Typography variant="h6" gutterBottom>Total Daily Spending: ${totalDailySpending}</Typography>
      <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
        <Button variant="contained" color="primary" onClick={() => setShowAddModal(true)}>
          Add Campaign
        </Button>
      </div>
      <Grid container spacing={2}>
        {marketingCampaigns.map(campaign => (
          <Grid item xs={12} sm={6} md={4} key={campaign.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{campaign.source}</Typography>
                <Typography variant="body2">Daily Budget: ${campaign.dailyBudget}</Typography>
                <Typography variant="body2">Location: {campaign.location}</Typography>
                <Typography variant="body2">Daily Spent: ${campaign.dailySpent || 0}</Typography>
                <Typography variant="body2">Status: {campaign.status}</Typography>
                <Typography variant="body2">Start Date: {campaign.startDate}</Typography>
                {campaign.ongoing ? (
                  <Typography variant="body2">Ongoing: Yes</Typography>
                ) : (
                  <Typography variant="body2">End Date: {campaign.endDate || 'N/A'}</Typography>
                )}
              </CardContent>
              <CardActions>
                <Button size="small" variant="outlined" onClick={() => handleEdit(campaign)}>Edit</Button>
                <Button size="small" color="error" variant="contained" onClick={() => handleDelete(campaign.id)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <AddMarketingCampaignModal open={showAddModal} onClose={() => setShowAddModal(false)} />
      {selectedCampaign && (
        <EditMarketingCampaignModal 
          open={showEditModal} 
          onClose={() => setShowEditModal(false)} 
          campaign={selectedCampaign}
        />
      )}
    </Container>
  );
};

export default MarketingPage;
