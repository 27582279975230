// src/services/marketingCampaigns.js
import { db } from '../firebase';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';

const campaignsRef = collection(db, 'marketingCampaigns');

export async function getAllMarketingCampaigns() {
  const snapshot = await getDocs(campaignsRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function addMarketingCampaign(campaignData) {
  const newCampaign = {
    ...campaignData,
    createdAt: Timestamp.now(),
    dailySpent: campaignData.dailySpent || 0 // default 0 if not provided
  };
  const docRef = await addDoc(campaignsRef, newCampaign);
  return docRef.id;
}

export async function updateMarketingCampaign(campaignId, updateData) {
  const campaignDoc = doc(db, 'marketingCampaigns', campaignId);
  await updateDoc(campaignDoc, {
    ...updateData,
    updatedAt: Timestamp.now()
  });
}

export async function deleteMarketingCampaign(campaignId) {
  const campaignDoc = doc(db, 'marketingCampaigns', campaignId);
  await deleteDoc(campaignDoc);
}
