// src/components/AddCatalogItemModal.js
import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button, Typography, Paper } from '@mui/material';
import { CRMContext } from '../context/CRMContext';

const AddCatalogItemModal = ({ open, onClose }) => {
  const { addNewCatalogItem } = useContext(CRMContext); // Use addNewCatalogItem instead of addNewProduct
  const [formValues, setFormValues] = useState({ name: '', price: '' });

  async function handleSubmit() {
    // Validate input
    if (!formValues.name.trim() || !formValues.price.trim() || isNaN(Number(formValues.price))) {
      alert('Please enter a valid name and numeric price.');
      return;
    }

    await addNewCatalogItem({
      name: formValues.name,
      price: Number(formValues.price)
    });
    onClose();
    setFormValues({ name: '', price: '' });
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box 
        component={Paper}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          p: 4, 
          width: 400
        }}
      >
        <Typography variant="h5" gutterBottom>Add Catalog Item</Typography>
        <TextField 
          fullWidth 
          label="Name" 
          margin="normal" 
          value={formValues.name} 
          onChange={e => setFormValues({...formValues, name: e.target.value})}
        />
        <TextField 
          fullWidth 
          label="Price" 
          margin="normal"
          value={formValues.price} 
          onChange={e => setFormValues({...formValues, price: e.target.value})}
        />

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddCatalogItemModal;
