// src/components/AddDealModal.js
import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box, TextField, Button, Typography, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { CRMContext } from '../context/CRMContext';

const AddDealModal = ({ open, onClose }) => {
  const { addNewDeal, catalogItems } = useContext(CRMContext);
  const [formValues, setFormValues] = useState({ name: '', value: '', stage: 'Lead', itemIds: [] });
  const [valueMode, setValueMode] = useState('custom');

  useEffect(() => {
    if (valueMode === 'catalog') {
      // Calculate value based on selected catalog items
      const selectedItems = catalogItems.filter(i => formValues.itemIds.includes(i.id));
      const total = selectedItems.reduce((sum, i) => sum + Number(i.price), 0);
      setFormValues(values => ({ ...values, value: total.toString() }));
    }
  }, [valueMode, formValues.itemIds, catalogItems]);

  async function handleSubmit() {
    await addNewDeal({
      name: formValues.name,
      value: Number(formValues.value), 
      stage: formValues.stage,
      products: formValues.itemIds // We'll still store 'Catalog Items' field in the deal for compatibility, but it's actually catalog items now.
    });
    onClose();
    setFormValues({ name: '', value: '', stage: 'Lead', itemIds: [] });
    setValueMode('custom');
  }

  const handleItemChange = (e) => {
    const { value } = e.target;
    setFormValues(f => ({ ...f, itemIds: typeof value === 'string' ? value.split(',') : value }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box component={Paper} sx={{
        position: 'absolute', top: '50%', left: '50%', 
        transform: 'translate(-50%, -50%)', p: 4, width: 400
      }}>
        <Typography variant="h5" gutterBottom>Add Deal</Typography>

        <TextField 
          fullWidth 
          label="Deal Name"
          margin="normal"
          value={formValues.name}
          onChange={e => setFormValues({ ...formValues, name: e.target.value })}
        />
        <TextField
          fullWidth
          label="Stage"
          margin="normal"
          value={formValues.stage}
          onChange={e => setFormValues({ ...formValues, stage: e.target.value })}
        />

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Deal Value Mode</FormLabel>
          <RadioGroup row value={valueMode} onChange={e => setValueMode(e.target.value)}>
            <FormControlLabel value="custom" control={<Radio />} label="Custom Value" />
            <FormControlLabel value="catalog" control={<Radio />} label="From Catalog Items" />
          </RadioGroup>
        </FormControl>

        {valueMode === 'custom' ? (
          <TextField
            fullWidth
            label="Value"
            margin="normal"
            value={formValues.value}
            onChange={e => setFormValues({ ...formValues, value: e.target.value })}
          />
        ) : (
          <FormControl fullWidth margin="normal">
            <Select
              multiple
              value={formValues.itemIds}
              onChange={handleItemChange}
              renderValue={(selected) => {
                const selectedItems = catalogItems.filter(i => selected.includes(i.id));
                return selectedItems.map(i => i.name).join(', ');
              }}
            >
              {catalogItems.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox checked={formValues.itemIds.indexOf(item.id) > -1} />
                  <ListItemText primary={`${item.name} ($${item.price})`} />
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Total Value: ${formValues.value}
            </Typography>
          </FormControl>
        )}

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default AddDealModal;
