// src/pages/Dashboard.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Dashboard = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Paper style={{ padding: '2rem' }}>
        <Typography variant="h4" gutterBottom>Dashboard</Typography>
        <Typography variant="body1">
          Welcome to your CRM dashboard. Key metrics and recent activities will appear here.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Dashboard;
