// src/components/DealCard.js
import React from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';

const DealCard = ({ deal, onEdit, onDelete }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{deal.name}</Typography>
        <Typography variant="body2">Value: {deal.value}</Typography>
        <Typography variant="body2">Stage: {deal.stage}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="outlined" onClick={() => onEdit(deal)}>
          Edit
        </Button>
        <Button size="small" color="error" variant="contained" onClick={() => onDelete(deal.id)}>
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default DealCard;
